import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

const PrivacyPolicy = () => {
  return (
    <Box maxW="5xl" mx="auto" p={5}>
      <Heading as="h1" size="xl" mb={5}>Privacy Policy</Heading>
      <Text mb={4}>
        This privacy policy ("Policy") explains how we collect, use, and disclose your personal data when you visit our website or use our services.
      </Text>

      <Heading as="h2" size="md" mt={4} mb={2}>1. Information We Collect</Heading>
      <Text>
        We collect information that you provide directly to us, such as when you sign up for an account, fill out a form, or contact us. This may include your name, email address, phone number, etc.
      </Text>

      <Heading as="h2" size="md" mt={4} mb={2}>2. How We Use Your Information</Heading>
      <Text>
        We use your information to provide our services, communicate with you, and improve our offerings.
      </Text>

      <Heading as="h2" size="md" mt={4} mb={2}>3. Data Protection</Heading>
      <Text>
        We take appropriate measures to protect your personal data from unauthorized access, disclosure, and misuse.
      </Text>

      <Heading as="h2" size="md" mt={4} mb={2}>4. Your Rights</Heading>
      <Text>
        You have the right to access, modify, and delete your personal data. You can also object to certain uses of your data.
      </Text>

      <Heading as="h2" size="md" mt={4} mb={2}>5. Changes to This Policy</Heading>
      <Text>
        We may update this Policy from time to time. Any changes will be posted on this page.
      </Text>
    </Box>
  );
};

export default PrivacyPolicy;