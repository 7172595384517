import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

const TermsAndConditions = () => {
  return (
    <Box maxW="5xl" mx="auto" p={5}>
      <Heading as="h1" size="xl" mb={5}>Terms and Conditions</Heading>
      <Text mb={4}>
        Welcome to our platform. These terms and conditions ("Terms") govern your use of our website and services. Please read them carefully.
      </Text>

      <Heading as="h2" size="md" mt={4} mb={2}>1. Acceptance of Terms</Heading>
      <Text>
        By accessing or using our services, you agree to be bound by these Terms. If you do not agree, you should not use our platform.
      </Text>

      <Heading as="h2" size="md" mt={4} mb={2}>2. Use of Services</Heading>
      <Text>
        You agree to use our services only for lawful purposes and in accordance with these Terms. You must not misuse our services in any way.
      </Text>

      <Heading as="h2" size="md" mt={4} mb={2}>3. Account Responsibilities</Heading>
      <Text>
        You are responsible for maintaining the confidentiality of your account and password. You agree to notify us immediately if there is any unauthorized use of your account.
      </Text>

      <Heading as="h2" size="md" mt={4} mb={2}>4. Termination</Heading>
      <Text>
        We may suspend or terminate your access to our services if you violate these Terms or for any other reason.
      </Text>

      <Heading as="h2" size="md" mt={4} mb={2}>5. Changes to Terms</Heading>
      <Text>
        We reserve the right to update these Terms at any time. Any changes will be posted on this page, and continued use of our services indicates your acceptance of the new Terms.
      </Text>
    </Box>
  );
};

export default TermsAndConditions;