import React, { useState, Suspense, lazy } from 'react';
import { chakra, Container, Stack, HStack, Text, Box, Input, Button, Select, Checkbox, Alert, AlertIcon, Image, Skeleton, Flex, Spacer, Heading, IconButton, Spinner, Link, CheckboxGroup, SimpleGrid, Menu, MenuButton, MenuList, MenuItem, Tag, TagLabel, TagLeftIcon, VStack } from '@chakra-ui/react';

import { FaGithub, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { ArrowForwardIcon, ChevronDownIcon, CheckIcon } from '@chakra-ui/icons';
import { FaGift } from 'react-icons/fa';

import logo from '../assets/logo.png';
const FeatureList = lazy(() => import('./SellerOnboarding/Features'));


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const ComingSoon = () => {
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [basicInfo, setBasicInfo] = useState({ name: '', email: '', phone: ''});
    const [additionalInfo, setAdditionalInfo] = useState({ selling: [], termsAccepted: true, revenue: '', platform: false, pricing: '', comments: '', platforms: [] });
    const [errors, setErrors] = useState({}); // State to track errors
    const [status, setStatus] = useState(null);
    const [userId, setUserId] = useState(null);
    const [submissionComplete, setSubmissionComplete] = useState(false);

    const overlayStyles = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    // const [toast] = useToast();

    const validateBasicInfo = () => {
        const errors = {};

        // Check if name is empty
        if (!basicInfo.name.trim()) {
            errors.name = 'Name is required';
        }

        // Check if email is valid
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(basicInfo.email)) {
            errors.email = 'Valid email is required';
        }

        // If phone is provided, check if it's valid (optional field)
        const phoneRegex = /^(?:\d{10}|0\d{10}|91\d{10}|\+91\d{10})$/; // Simplified regex for example purposes
        if (basicInfo.phone && !phoneRegex.test(basicInfo.phone)) {
            errors.phone = 'Valid phone number is required';
        }

        // Check if terms and conditions are accepted
        if (!additionalInfo.termsAccepted) {
            errors.terms = 'You must accept the terms and conditions.';
        }

        return errors;
    };

    const handleBasicSubmit = async (e) => {
        e.preventDefault();
        setStatus(null);
        setLoading(true);

        // Run validation first
        const validationErrors = validateBasicInfo();

        if (Object.keys(validationErrors).length > 0) {
            // If there are validation errors, set the error state and do not proceed with API call
            setErrors(validationErrors);
            return;
        }

        // If no validation errors, clear any existing errors
        setErrors({});

        // API call to store basic info
        try {
            const response = await fetch(`${apiBaseUrl}/api/v1/store-basic-info`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(basicInfo),
            });

            if (response.ok) {
                const responseData = await response.json();
                const userId = responseData.userId;
                setUserId(userId);
                setStep(2);  // Move to the secondary form step
            } else {
                setStatus('error');
            }
        } catch (error) {
            setStatus('error');
        } finally {
            setLoading(false);  // Hide the overlay after completion
        }
    };

    const handleAdditionalSubmit = async (e) => {
        e.preventDefault();
        setStatus(null);
        setLoading(true);

        const formData = {
            ...basicInfo,
            ...additionalInfo,
            userId
        };

        try {
            // API call to update the additional info
            const response = await fetch(`${apiBaseUrl}/api/v1/update-info`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // Mark the entire submission process as complete
                setSubmissionComplete(true);
                setStatus('success');
            } else {
                setStatus('error');
            }
        } catch (error) {
            setStatus('error');
        } finally {
            setLoading(false);  // Hide the overlay after completion
        }
    };

    const renderFinalSuccessMessage = () => (
        <Alert status="success" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" mb={6}>
            <AlertIcon boxSize="40px" mr={0} />
            <Box>
                <Text fontSize="lg" fontWeight="bold">
                    Thank you, {basicInfo.name}!
                </Text>
                <Text>
                    We will get back to you as soon as possible with launch details and a special onboarding offer{' '}
                    <FaGift style={{ display: 'inline', color: 'green.500' }} />
                </Text>
            </Box>
        </Alert>
    );

    const basicInfoForm = () => {
        return (
            <Box as="form" onSubmit={handleBasicSubmit} mt={8}>
                {/* Name and Email on the same line */}
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mb={4}>
                    <Flex position="relative">
                        <Input
                            placeholder="Enter your name"
                            value={basicInfo.name}
                            onChange={(e) => setBasicInfo({ ...basicInfo, name: e.target.value })}
                            isRequired
                            size="md"
                            h="40px"
                            borderColor={errors.name ? "red.500" : "gray.300"}
                            _focus={{ borderColor: errors.name ? "red.500" : "blue.500" }}
                            position="relative"  // Ensures no injected elements affect this
                        />
                    </Flex>
                    <Flex position="relative">
                        <Input
                            placeholder="Enter your email"
                            value={basicInfo.email}
                            onChange={(e) => setBasicInfo({ ...basicInfo, email: e.target.value })}
                            type="email"
                            isRequired
                            size="md"
                            h="40px"
                            borderColor={errors.email ? "red.500" : "gray.300"}
                            _focus={{ borderColor: errors.email ? "red.500" : "blue.500" }}
                            position="relative"
                        />
                    </Flex>
                </SimpleGrid>

                {/* Phone and Submit Button on the same line */}
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    <Flex position="relative">
                        <Input
                            placeholder="Enter your phone (optional)"
                            value={basicInfo.phone}
                            onChange={(e) => setBasicInfo({ ...basicInfo, phone: e.target.value })}
                            type="tel"
                            size="md"
                            h="40px"
                            borderColor={errors.phone ? "red.500" : "gray.300"}
                            _focus={{ borderColor: errors.phone ? "red.500" : "blue.500" }}
                            position="relative"
                        />
                    </Flex>
                    <Flex justify="flex-end">
                        <Button type="submit" rightIcon={<ArrowForwardIcon />} colorScheme='teal' variant='outline' w="100%" position="relative">
                            Share your Details
                        </Button>

                    </Flex>
                    
                </SimpleGrid>
                <Checkbox
                        isChecked={additionalInfo.termsAccepted}
                        onChange={(e) => setAdditionalInfo({ ...additionalInfo, termsAccepted: e.target.checked })}
                        isRequired
                        checked
                        mb={4}
                        mt={10}
                    >
                        I agree to the <Link href="/terms-and-conditions" isExternal color="teal.500">Terms and Conditions</Link> and <Link href="/privacy-policy" isExternal color="teal.500">Privacy Policy</Link>.
                </Checkbox>
            </Box>
        );
    }

    const Footer = () => {
        return (
            <Box as="footer" py={8}>
                <Stack
                    maxW="5xl"
                    marginInline="auto"
                    spacing={{ base: 8, md: 0 }}
                    justifyContent="space-between"
                    alignItems="center"
                    direction={{ base: 'column', md: 'row' }}
                >
                    {/* Company Name */}

                    <Box cursor="pointer">

                    </Box>

                    {/* Links Section */}
                    <HStack spacing={4} alignItems="center">
                        {/* <FooterLink href="#">About Us</FooterLink> */}
                        <FooterLink href="/privacy-policy">Privacy Policy</FooterLink>
                        <FooterLink href="/terms-and-conditions">Terms and Conditions</FooterLink>
                        {/* <FooterLink href="#">Contact</FooterLink> */}
                    </HStack>

                    {/* Social Icons */}
                    <HStack spacing={4}>
                        <SocialIcon
                            href="https://www.instagram.com/minitaka.online/"
                            ariaLabel="Instagram Account"
                            icon={<FaInstagram />}
                        />
                        <SocialIcon
                            href="https://x.com/MinitakaOnline"
                            ariaLabel="Twitter Account"
                            icon={<FaTwitter />}
                        />
                        
                    </HStack>
                </Stack>
            </Box>
        );
    };

    // Reusable FooterLink component
    const FooterLink = ({ href, children }) => (
        <Link href={href} fontSize="sm" _hover={{ textDecoration: 'underline' }}>
            {children}
        </Link>
    );

    // Reusable SocialIcon component
    const SocialIcon = ({ href, ariaLabel, icon }) => (
        <IconButton
            as={Link}
            href={href}
            aria-label={ariaLabel}
            icon={icon}
            variant="outline"
            rounded="full"
            size="md"
            isExternal
        />
    );

    // Function to render the selling dropdown
    const renderSellingDropdown = () => {
        // Create a display label based on selected items
        const selectedItemsLabel = additionalInfo.selling.length > 0
            ? additionalInfo.selling.join(', ')
            : 'Select what you are selling';

        return (
            <Menu closeOnSelect={false}>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} mb={4} fontWeight="normal" w="100%" variant="outline">
                    {selectedItemsLabel}
                </MenuButton>
                <MenuList>{generateSellingMenuItems()}</MenuList>
            </Menu>
        );
    };

    // Function to generate the menu items
    const generateSellingMenuItems = () => {
        const sellingOptions = [
            "Clothing",
            "Handicrafts",
            "Food and Beverages",
            "Home Decor",
            "Accessories",
            "Electronics",
            "Others"
        ];

        return sellingOptions.map((option) => (
            <MenuItem key={option}>
                <Checkbox
                    isChecked={additionalInfo.selling.includes(option)}
                    onChange={(e) => handleSellingChange(option, e.target.checked)}
                >
                    {option}
                </Checkbox>
            </MenuItem>
        ));
    };
    // Function to handle checkbox changes for selling
    const handleSellingChange = (item, isChecked) => {
        if (isChecked) {
            setAdditionalInfo((prev) => ({
                ...prev,
                selling: [...prev.selling, item],
            }));
        } else {
            setAdditionalInfo((prev) => ({
                ...prev,
                selling: prev.selling.filter((i) => i !== item),
            }));
        }
    };

    const renderPlatformTags = () => {
        const platformOptions = [
            "Quick Commerce",
            "Amazon/Flipkart",
            "Own Website",
            "Social Media",
            "Any Other",  // Added "Any Other" option
        ];

        return platformOptions.map((platform) => (
            <Tag
                size="lg"
                key={platform}
                variant="outline"  // Always outline for tag style
                colorScheme={additionalInfo.platforms.includes(platform) ? "green" : "gray"}  // Green when selected, grey otherwise
                onClick={() => handlePlatformChange(platform)}
                cursor="pointer"
                m={1}
            >
                <TagLeftIcon
                    boxSize="12px"
                    as={CheckIcon}
                    color={additionalInfo.platforms.includes(platform) ? "green.500" : "gray.400"}  // Icon is green when selected, grey otherwise
                />
                <TagLabel>{platform}</TagLabel>
            </Tag>
        ));
    };


    // Function to handle platform selection (checkbox behavior)
    const handlePlatformChange = (platform) => {
        if (additionalInfo.platforms.includes(platform)) {
            setAdditionalInfo((prev) => ({
                ...prev,
                platforms: prev.platforms.filter((p) => p !== platform),  // Remove the platform if already selected
            }));
        } else {
            setAdditionalInfo((prev) => ({
                ...prev,
                platforms: [...prev.platforms, platform],  // Add platform if not selected
            }));
        }
    };

    const secondaryInfoForm = () => {
        return (
            <Box fontSize="sm">
                {/* Success message with user's name */}
                <Alert status="success" mb={6}>
                    <AlertIcon />
                    Thank you, {basicInfo.name}! <br />We have recorded your interest and will get back to you soon.
                </Alert>

                <Box as="form" onSubmit={handleAdditionalSubmit}>
                    {/* What are you selling? dropdown with checkboxes */}
                    <Text mb={4} fontSize="md" fontWeight="bold">Would you like to provide more details?</Text>
                    {renderSellingDropdown()}

                    {/* Select Monthly Revenue and Preferred Pricing Model on the same line */}
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mb={4}>
                        <Select
                            placeholder="Select monthly revenue"
                            value={additionalInfo.revenue}
                            onChange={(e) => setAdditionalInfo({ ...additionalInfo, revenue: e.target.value })}
                        >
                            <option value="less_than_1_lakh">Less than 1 Lakh</option>
                            <option value="1_to_5_lakhs">1 to 5 Lakhs</option>
                            <option value="5_to_10_lakhs">5 to 10 Lakhs</option>
                            <option value="more_than_10_lakhs">More than 10 Lakhs</option>
                        </Select>

                        <Select
                            placeholder="Preferred pricing model"
                            value={additionalInfo.pricing}
                            onChange={(e) => setAdditionalInfo({ ...additionalInfo, pricing: e.target.value })}
                        >
                            <option value="percentage">Percentage per sale</option>
                            <option value="subscription">Subscription</option>
                        </Select>
                    </SimpleGrid>

                    {/* Title for 'Are you available on any platform?' */}
                    <Text fontWeight="normal" mb={2}>Are you available on any platform?</Text>

                    {/* Platform options as tags (checkboxes with checkmark) */}
                    <CheckboxGroup colorScheme="green">
                        <Flex wrap="wrap" mb={4}>
                            {renderPlatformTags()}
                        </Flex>
                    </CheckboxGroup>

                    {/* Button should appear in its own line at the bottom */}
                    <Box mt={6}>
                        <Button type="submit" colorScheme="blue" size="lg" w="100%">
                            Save Details
                        </Button>
                    </Box>
                </Box>

                {status === 'success' && (
                    <Alert status="success" mt={4}>
                        <AlertIcon />
                        Details saved successfully! A confirmation email has been sent to you.
                    </Alert>
                )}
                {status === 'error' && (
                    <Alert status="error" mt={4}>
                        <AlertIcon />
                        Something went wrong. Please try again later.
                    </Alert>
                )}
            </Box>
        );
    };


    return (
        <>
            {/* Navbar Section */}
            <Container maxW="8xl" p={{ base: 5, md: 10 }}>
                <Flex mb="0px" align="center">
                    <HStack>
                        <Link href="#" passhref="true">
                            <Box>
                                <Image w="150px" src={logo} alt='MiniTaka : Sell Anything' />
                            </Box>
                        </Link>
                    </HStack>
                    <Spacer />
                </Flex>
            </Container>

            {/* Main Container */}
            <Container maxW="7xl" px={{ base: 6, md: 3 }} py={4}>
                <Stack
                    direction={{ base: 'column', lg: 'row' }}
                    justifyContent="space-between"
                    spacing={8}
                    alignItems={{ base: 'center', lg: 'flex-start' }} // Center alignment for smaller screens
                >
                    {loading && (
                    <Box style={overlayStyles}>
                        <Spinner size="xl" color="white" />
                        <Text color="white" mt={4}>Submitting, please wait...</Text>
                    </Box>
                    )}
                    {/* Left Section with Form */}
                    <Stack
                        direction="column"
                        spacing={6}
                        justifyContent="center"
                        width={{ base: '100%', lg: '60%' }} // Full width for smaller screens, 60% for large
                    >
                        <chakra.h1 fontSize="3xl" lineHeight={1} fontWeight="bold" textAlign="left">
                            Empower Your Homegrown Business
                            <br />
                            <Text fontSize="xl" mt={4}>Sell, Manage, and Grow with Ease!</Text>
                        </chakra.h1>

                        <Text fontSize="md" textAlign="left" lineHeight="1.375" fontWeight="400" color="gray.500">
                            Elevate your homegrown business with our user-friendly eCommerce platform, designed to simplify selling and foster customer connections.
                        </Text>

                        {step === 1 && (
                            <>
                                <Text fontSize="md" textAlign="left" lineHeight="1.375" fontWeight="400" color="gray.500">
                                    Please share below details and we will get back to you with more information.
                                </Text>
                            </>
                        )}

                        {!submissionComplete ? (
                            <>
                                {step === 1 ? (
                                    basicInfoForm()
                                ) : (
                                    secondaryInfoForm()
                                )}
                            </>
                        ) : (
                            renderFinalSuccessMessage()
                        )}
                    </Stack>

                    {/* Right Section with Launching Soon Message */}
                    <LaunchingSoonSection />
                </Stack>
            </Container>

            <Suspense fallback={<Skeleton height="100px" />}>
                <FeatureList comingSoon={true} />
            </Suspense>

            {/* Footer Section */}
            <Footer />

            {/* Footer Section */}
        </>
    );
};

const LaunchingSoonSection = () => {
    const launchDate = new Date('2024-12-31');
    const today = new Date();
    const daysToGo = Math.ceil((launchDate - today) / (1000 * 60 * 60 * 24));

    return (
        <Box
            ml={{ base: 0, lg: 5 }}
            width={{ base: '100%', lg: 'auto' }} // Full width for smaller screens, auto for large
            minW={{ base: 'auto', md: '30rem' }}
            pos="relative"
        >
            <VStack
                align="center"
                justify="center"
                spacing={6}
                textAlign="center"
                px={4}
                py={10}
                bg="gray.50"
                borderRadius="md"
                h="100%"
            >
                <Heading as="h2" size="xl" color="blue.600">
                    We are Launching soon . . .
                </Heading>

                <Text fontSize="md" color="gray.600">
                    Exciting updates are on the way. We’ll be in touch soon with more details before our grand launch!
                </Text>

                <Text fontSize="md" color="gray.600">
                    Our innovative tools and seamless features are designed to help you sell effortlessly, connect with your customers, and scale your business with ease.
                </Text>

                <Box display="flex" alignItems="center">
                    <Text fontSize={{ base: '6xl', md: '7xl' }} fontWeight="bold" color="green.500">
                        {daysToGo}
                    </Text>
                    <Box ml={2} textAlign="left">
                        <Text fontSize="md" color="gray.500" fontWeight="medium">
                            days
                        </Text>
                        <Text fontSize="lg" color="gray.600" fontWeight="bold">
                            to go
                        </Text>
                    </Box>
                </Box>

                <Image
                    src="https://preview.uideck.com/items/launch/assets/img/shape-1.svg"
                    alt="Launch Shape"
                    position="absolute"
                    bottom="-50px"
                    right="10px"
                    width="150px"
                    opacity={0.5}
                />
            </VStack>
        </Box>
    );
};


export default ComingSoon;