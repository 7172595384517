import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import ThirdParty, { Github, Google, Facebook, Apple } from "supertokens-auth-react/recipe/thirdparty";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";

import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { ThirdPartyPreBuiltUI } from 'supertokens-auth-react/recipe/thirdparty/prebuiltui';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import * as reactRouterDom from "react-router-dom";


import customTheme from "./theme";
// import Onboarding from "./pages/Onboarding";
// import Dashboard from "./pages/Dashboard";
// import Details from './pages/Details';
// import Home from './pages/Home';
// import SignInPage from "./pages/Signin";
// import Consumer from "./pages/consumer";
// import CheckoutPage from "./pages/consumer/CheckoutPage";
// import ProductDetails from "./pages/ProductDetails";
// import ProductEdit from "./components/Product/ProductEdit";
// import AddProductPage from './components/Product/AddProductPage'; 
// import SellerHome from './pages/SellerOnboarding';
import ComingSoon from "./pages/ComingSoon";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";

SuperTokens.init({
  appInfo: {
      // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
      appName: "client",
      apiDomain: "http://localhost",
      websiteDomain: "http://localhost:3001",
      apiBasePath: "/auth",
      websiteBasePath: "/login"
  },
  disableAuthRoute: true, // This will prevent supertokens from showing anything on the main auth route. 
  recipeList: [
      ThirdParty.init({
          signInAndUpFeature: {
              providers: [
                  Github.init(),
                  Google.init(),
                  Facebook.init(),
                  Apple.init(),
              ]
          }
      }),
      EmailPassword.init(),
      Session.init()
  ],
  getRedirectionURL: async (context) => {
    if (context.action === "SUCCESS" && context.newSessionCreated) {
        return "/dashboard"; // defaults to "/"
    } else if (context.action === "TO_AUTH") {
        // The user will be taken to this path when they need to login.
        return "/auth"; // return the path where you are rendering the Auth UI
    };
  },
});

export default function App() {

  // Main return rendering
  return (
    <SuperTokensWrapper>
      <ChakraProvider theme={customTheme}>
      {/* <ColorModeSwitcher justifySelf="flex-end" /> */}

      <Router>
        {/* Loading indicator while session is being checked */}
        {/* {(isSessionLoading || isUserLoading) && (
          <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <Spinner size="xl" />
          </Box>
        )} */}

        <Routes>
          {/*This renders the login UI on the /login route*/}
          {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [ThirdPartyPreBuiltUI, EmailPasswordPreBuiltUI])}
                        {/*Your app routes*/}
          {/* Public route that doesn't require authentication */}
          {/* <Route path="/onboarding" element={<Onboarding />} /> */}
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          {/* <Route path="/products/:productId" element={<Details />} /> */}
          {/* <Route path="/details" element={<Details />} /> */}
          
          {/* <Route path="/seller" element={<SellerHome />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/consumer" element={<Consumer />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/products/:productId" element={<ProductDetails />} /> */}
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/" element={<ComingSoon />} />
          
          {/* <Route path="/products/update/:productId" component={UpdateProduct} /> */}
        </Routes>
      </Router>
      </ChakraProvider>
    </SuperTokensWrapper>
  );
}
